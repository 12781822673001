import React, { useState, useEffect } from "react";
import Owl from "../Utils/owl/Owl";
import Odd from "./Odd";
import { fetchUrl } from "../Utils/fetch";

export default function Odds() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getDataList();
    const interval = setInterval(() => {
      console.log(393);
      getDataList();
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  function getDataList() {
    fetch("https://rigi.ge/laravel/ecom/api/gallery/odds", {
      method: "GET"
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          //console.log("test", result);
        },
        (error) => {
          return "error";
        }
      );
  }

  return (
    <div>
      <div className="in-content-slider">
        <Owl images="slider_main" />
      </div>
      <div>
        {data.map((item) => (
          <Odd name={item.price} id={item.id} />
        ))}
        <div className="clear" />
      </div>
    </div>
  );
}
