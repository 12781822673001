import React, { useState, useEffect } from "react";
import "./Owl.scss?v=1";
import OwlCarousel from "react-owl-carousel3";
import axios from "../axios";

function Owl(probs) {
  const [dataslider, setDataslider] = React.useState([]);

  let images = probs.images;

  React.useEffect(() => {
    getDataList();
  }, []);

  let getDataList = async function () {
    axios
      .get("/gallery/cat/image/list/" + images)
      .then((response) => {
        setDataslider(response.data);

        //console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //console.log(images);
  if (dataslider.length > 0) {
    return (
      <div className="home-slider">
        <OwlCarousel items={1} autoplay autoplayTimeout={3000} nav loop={true}>
          {dataslider.map((item) => (
            <div key={item.id} className="item ">
              <img
                src={"https://rigi.ge/laravel/ecom/" + item.img_path}
                alt=""
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    );
  }
}

export default Owl;
