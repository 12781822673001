import React, { useState } from "react";
import "./Lightgallery.scss";
import LightGallery from "lightgallery/react";
import axios from "../axios";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import Pagination from "react-js-pagination";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Lightgalleryy({ imagesid }) {
  //let images = probs.images;
  const navigate = useNavigate();
  let query = useQuery();
  let title = query.get("title");
  const [paginateData, setPaginateData] = useState([]);
  function handlePageChange(pageNumber) {
    getDataList(pageNumber);
  }

  const [data, setData] = React.useState([]);
  let { id } = useParams();

  React.useEffect(() => {
    getDataList(imagesid, 1);
  }, [imagesid]);

  let getDataList = async function (imagesid, page) {
    axios
      .get("/gallery/cat/image/list/" + imagesid + "?page=" + page)
      .then((response) => {
        setData(response.data);
        setPaginateData(response.data);
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onInit = () => {
    //console.log("lightGallery has been initialized");
  };
  return (
    <>
      <ul className="gallery-container">
        <LightGallery
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
        >
          {data.map((item) => (
            <li href={"https://rigi.ge/laravel/ecom/" + item.img_path}>
              <img
                alt=""
                src={"https://rigi.ge/laravel/ecom/" + item.img_path}
              />
            </li>
          ))}
        </LightGallery>
      </ul>
      <Pagination
        hideDisabled={true}
        activePage={paginateData.current_page}
        itemsCountPerPage={paginateData.per_page}
        totalItemsCount={paginateData.total}
        pageRangeDisplayed={5}
        onChange={(pageNumber) => handlePageChange(pageNumber)}
      />
    </>
  );
}

export default Lightgalleryy;
