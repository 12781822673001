import React, { useState, useEffect } from "react";

import axios from "../Utils/axios";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Catlist({ path }) {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    getCatList(path);
  }, [path]);

  let getCatList = async function (path) {
    console.log(parseInt(path.slice(4)));
    axios
      .get("/gallery/cat/" + parseInt(path.slice(4)))
      .then((response) => {
        setList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (list.length > 0) {
    return (
      <div className="cat-list-cont-in">
        {list.map((item, index) => {
          return <>{item.name}</>;
        })}
      </div>
    );
  }
}
